import { toast } from "@izone/ui/components/sonner";
import { MutationCache, QueryClient } from "@tanstack/react-query";
import axios from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: 60 * 1000,
    },
    mutations: {
      onError: (error) => {
        if (import.meta.env.DEV) {
          console.debug("TanstackMutationError: ", error);
        }
        if (axios.isAxiosError(error)) {
          const message =
            error.response?.data.message ?? error.response?.data.detail;
          toast.error(
            message ??
              "Có thể đã có lỗi xảy ra. Chúng tôi đang cố gắng để khắc phục vấn đề."
          );
        }
      },
      onSuccess: (data: any) => {
        toast.success(
          (data?.data?.detail as string) ?? "Thực hiện thao tác thành công ."
        );
      },
    },
  },

  mutationCache: new MutationCache({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  }),
});
