import { cssBundleHref } from "@remix-run/css-bundle";
import type {
    LinkDescriptor,
    LinksFunction,
    MetaFunction,
} from "@remix-run/node";
import {
    isRouteErrorResponse,
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useRouteError,
} from "@remix-run/react";
import { QueryClientProvider } from "@tanstack/react-query";
import stylesheet from "~/app/base.css?url";
import ErrorPage from "~/components/error-page";
import NotFound from "~/components/not-found";
import { queryClient } from "~/lib/tanstack-query";

export function ErrorBoundary() {
    const error = useRouteError();

    if (isRouteErrorResponse(error) && error.status === 404) {
        return <NotFound />;
    }
    return <ErrorPage />;
}

export const links: LinksFunction = () => {
    const links: LinkDescriptor[] = [
        { rel: "stylesheet", href: stylesheet },
        { rel: "preconnect", href: "https://fonts.googleapis.com" },
        {
            rel: "preconnect",
            href: "https://fonts.gstatic.com",
            crossOrigin: "anonymous",
        },
        {
            rel: "stylesheet",
            href: "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&Geologica:wght@100..900&display=swap",
        },
        {
            rel: "icon",
            type: "image/svg+xml",
            href: "/favicon.ico",
        },
    ];
    if (cssBundleHref) {
        links.push({ rel: "stylesheet", href: cssBundleHref });
    }

    return links;
};

export const meta: MetaFunction = () => {
    return [
        {
            title: "IZONE - Chiến lược học thi IELTS",
        },
    ];
};

export function Layout({ children }: { children: React.ReactNode }) {
    return (
        <html lang="en">
            <head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <Meta />
                <Links />
            </head>
            <body className="antialiased">
                {children}
                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    );
}

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Outlet />
        </QueryClientProvider>
    );
}
